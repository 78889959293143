.techs__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.tech {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.tech:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: pointer;
}

.tech__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.15);
}

.tech__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.tech__list {
    padding: 2rem;
}

.tech__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.tech__list p {
    font-size: 0.9rem;
}

.tech__list-icon {
    color: var(--color-primary)
}

/* ********** MEDIA QUERIES (MEDIUM DEVICES) ********** */

@media screen and (max-width: 1024px) {
    .techs__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .tech {
        height: auto;
    }
}

/* ********** MEDIA QUERIES (SMALL DEVICES) ********** */

@media screen and (max-width: 600px) {
    .techs__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}