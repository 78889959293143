.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 88;
}

.modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    background-color: var(--color-bg);
    /* background-color: rgba(0, 0, 0, 0.9); */
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    z-index: 99;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
